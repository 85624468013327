import React, { useState } from 'react'
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby"
import htmlDecode from "html-entities-decoder"

import "./Services.css";


function Services(props) {

    console.log(props)
    const data = props.data;
    return (
        <div className="pt-16 md:pt-32 m-auto text-center" style={props.stylez ? props.stylez : { display: "block" }}>
            {data.title && (
                <>
                    <p className="section-name">{data.sectionName}</p>
                    <h2 className="" >
                        {data.title}
                    </h2>
                    <p className="m-auto mt-4" style={{ maxWidth: "500px" }}>{data.servicesDescription}</p>
                </>
            )}
            {props.data.services.map((services, index) => (
                <div key={`services-${services.node.name}`} style={index === 1 ? { backgroundColor: "#f8f9fa" } : { backgroundColor: "#ffffff" }} className="py-8 md:py-16 text-left" >
                    <div className="m-auto" style={{ maxWidth: "1280px" }}>
                        <div className="flex flex-wrap py-4">
                            {services.node.services && services.node.services.edges.map((service, yndex) => (
                                <div key={`service-${service.node.title}`} className="w-full md:w-1/3 flex relative">
                                    <Link to={`${service.node.uri}`} className="m-8 m-1 service-tab relative" style={{ backgroundColor: "#f6faff" }} >
                                        <div>
                                            <GatsbyImage
                                                image={service.node.servicesAcf.featuredImage.imageFile.childImageSharp.gatsbyImageData}
                                                alt={service.node.servicesAcf.featuredImage.altText}
                                                className="m-auto sc-m-img" />
                                        </div>
                                        <div className="flex items-center pb-8 pt-4">
                                            <div style={{ width: "70px" }}>
                                                <div className="s-ic-c">
                                                    <GatsbyImage
                                                        image={service.node.servicesAcf.smallIcon.imageFile.childImageSharp.gatsbyImageData}
                                                        alt={service.node.servicesAcf.smallIcon.altText}
                                                        className="services-ovr m-auto block" />
                                                </div>
                                            </div>
                                            <div className="w-3/4">
                                                {data.title ?
                                                    <h3 className="text-2xl py-2 services-h4 px-6 service-h4">
                                                        {htmlDecode(service.node.title)}
                                                    </h3>
                                                    :
                                                    <h2 className="text-2xl py-4 px-6 service-h4">
                                                        {htmlDecode(service.node.title)}
                                                    </h2>
                                                }
                                                <div dangerouslySetInnerHTML={{ __html: service.node.excerpt }} className="px-6" />
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            ))}

                            {services.node.examinations && services.node.examinations.edges.map((service, yndex) => (
                                <div key={`service-${service.node.title}`} className="w-full md:w-1/3 flex relative">
                                    <Link to={`${service.node.uri}`} className="m-8 m-1 service-tab relative" style={{ backgroundColor: "#f6faff" }} >
                                        <div>
                                            <GatsbyImage
                                                image={service.node.servicesAcf.featuredImage.imageFile.childImageSharp.gatsbyImageData}
                                                alt={service.node.servicesAcf.featuredImage.altText}
                                                className="m-auto sc-m-img" />
                                        </div>
                                        <div className="flex items-center pb-8 pt-4">
                                            <div style={{ width: "70px" }}>
                                                <div className="s-ic-c">
                                                    <GatsbyImage
                                                        image={service.node.servicesAcf.smallIcon.imageFile.childImageSharp.gatsbyImageData}
                                                        alt={service.node.servicesAcf.smallIcon.altText}
                                                        className="services-ovr m-auto block" />
                                                </div>
                                            </div>
                                            <div className="w-3/4">
                                                {data.title ?
                                                    <h4 className="text-2xl py-2 services-h4 px-6 service-h4">
                                                        {htmlDecode(service.node.title)}
                                                    </h4>
                                                    :
                                                    <h2 className="text-2xl py-4 px-6 service-h4">
                                                        {htmlDecode(service.node.title)}
                                                    </h2>
                                                }
                                                <div dangerouslySetInnerHTML={{ __html: service.node.excerpt }} className="px-6" />
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Services;

// export const query = graphql`

//   fragment ServicesFragmentIn on WPGraphQL_RootQueryToServiceCategoryConnection {
//     edges {
//         node {
//             name
//             services (first: 200, where: {orderby: {field: MENU_ORDER, order: ASC}}){
//                 edges{
//                     node{
//                         uri
//                         title
//                         excerpt
//                         servicesAcf{
//                             smallIcon{
//                                 altText
//                                 sourceUrl
//                                 imageFile{
//                                     childImageSharp{
//                                         fixed(width: 40){
//                                             base64
//                                             width
//                                             height
//                                             src
//                                             srcSet
//                                         }
//                                     }
//                                 }
//                             }
//                             featuredImage{
//                                 altText
//                                 sourceUrl
//                                 imageFile{
//                                     childImageSharp{
//                                         fluid(maxWidth: 360){
//                                             base64
//                                             aspectRatio
//                                             src
//                                             srcSet
//                                             srcWebp
//                                             srcSetWebp
//                                             sizes
//                                         }
//                                     }
//                                 }
//                             }
//                         }

//                     }
//                 }
//             }
//         }
//     }
//   }
// `